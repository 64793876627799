






























































import { Component, Prop, Watch } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import { mixins } from "vue-class-component";
import CompanyMixin from "@/mixins/CompanyMixin";

@Component({
  components: {
    FscInputDecimal,
    Validation,
    SaveButton,
    AbortButton,
    FscCard,
  },
})
export default class PriceEditForm extends mixins(CompanyMixin) {
  public name = "PriceEditForm";

  public priceInEur = null;
  public priceInEurForAdditionalClass = null;
  protected vat = null;
  protected bookAtStudentRegistration = false;
  protected enablePriceListForDrivebuzz = true;

  @Prop()
  public item!: any;

  @Prop({ default: () => [] })
  public vats!: any;

  @Prop({ default: () => false })
  public isLoading!: any;

  protected onAbort(): void {
    this.$emit("on-abort");
  }

  protected onSubmit(): void {
    if (this.hasNotPermissionWrite) return;

    this.$v.$touch();

    if (this.$v.$invalid) return;

    this.$emit("on-submit", {
      priceInEur: this.priceInEur,
      priceInEurForAdditionalClass: this.priceInEurForAdditionalClass,
      bookAtStudentRegistration: this.bookAtStudentRegistration,
      enablePriceListForDrivebuzz: this.enablePriceListForDrivebuzz,
      vat: this.vat,
    });
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      priceInEur: { required },
      vat: { required },
    };
  }

  @Watch("item", { immediate: true, deep: true })
  protected init(data: any): void {
    if (data) {
      this.priceInEur = data.priceInEur;
      this.priceInEurForAdditionalClass = data.priceInEurForAdditionalClass;
      this.bookAtStudentRegistration = data.bookAtStudentRegistration;
      this.enablePriceListForDrivebuzz = !!data.enablePriceListForDrivebuzz === false ? false : true;
      this.vat = data.vat;
    }
  }

  protected get isAllowedBook(): boolean {
    return this.item.product.bookAtStudentRegistrationAllowed;
  }
}
