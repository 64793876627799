












































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import CompanyMixin from "@/mixins/CompanyMixin";
import { mixins } from "vue-class-component";

@Component({
  components: { Validation, SaveButton, AbortButton, FscCard },
})
export default class PriceCombinationForm extends mixins(CompanyMixin) {
  public name = "PriceCombinationForm";

  @Prop({ default: () => [] })
  public products!: any;

  @Prop({ default: () => [] })
  public licenseClasses!: any;

  @Prop({ default: () => [] })
  public grantingTypes!: any;

  @Prop({ default: () => [] })
  public vats!: any;

  @Prop({ default: () => false })
  public isLoading!: any;

  @Prop({ default: () => null, type: Function })
  public checkBookAtStudentRegistrationAllowed!: any;

  @Prop({ default: () => false, type: Boolean })
  public isBookAtStudentRegistrationAllowed!: any;

  protected product = null;
  protected licenseClass = null;
  protected grantingType = [];
  protected vat = null;
  protected bookAtStudentRegistration = false;
  protected pricesAccordingGrantType = this.pricesAccordingGrantTypeOption[0];

  protected onAbort(): void {
    this.$emit("on-abort");
  }

  protected onSubmit(): void {
    if (this.hasNotPermissionWrite) return;

    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    this.$emit("on-submit", {
      product: this.product,
      licenseClass: this.licenseClass,
      grantingType: this.grantingType,
      vat: this.vat,
      bookAtStudentRegistration: this.bookAtStudentRegistration,
      enablePriceListForDrivebuzz: true,
    });
  }

  protected get pricesAccordingGrantTypeOption(): Array<any> {
    return [
      {
        value: 0,
        name: this.$t("general.no"),
      },
      {
        value: 1,
        name: this.$t("general.yes"),
      },
    ];
  }

  protected get isPricesAccordingGrantType(): boolean {
    return this.pricesAccordingGrantType.value;
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      product: { required },
      licenseClass: { required },
      vat: { required },
    };
  }

  @Watch("product", { deep: true })
  protected onChangeProduct(product: any): void {
    if (product) {
      this.checkBookAtStudentRegistrationAllowed(product.id);
    }
  }

  protected onSelectProduct(): void {
    this.bookAtStudentRegistration = false;
  }
}
