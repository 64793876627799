






























































































































































import { Component, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import { namespace } from "vuex-class";
import FscCardTable from "@/views/PriceList/FscCardTable.vue";
import FscCard from "@/components/Card/FscCard.vue";
import CreateCard from "@/views/PriceList/CreateCard.vue";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import Table from "@/components/Table.vue";
import PriceCombinationForm from "@/views/PriceList/PriceCombinationForm.vue";
import { TranslateResult } from "vue-i18n";
import PriceEditForm from "@/views/PriceList/PriceEditForm.vue";
import { mixins } from "vue-class-component";
import PriceMixin from "@/mixins/PriceMixin";
import PrintButton from "@/components/Button/PrintButton.vue";
import { AxiosError } from "axios";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import ModalMixin from "@/mixins/ModalMixin";
import ArchiveModal from "@/components/Modal/ArchiveModal.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import { getFile } from "@/utils/File";
import { BLANK_ID, STANDARD_ID } from "@/constants/PriceListTemplates";
import CopyButton from "@/components/Button/CopyButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import DeleteModal from "@/components/Modal/DeleteModal.vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import PriceListFilter from "@/views/PriceList/PriceListFilter.vue";
import CompanyMixin from "@/mixins/CompanyMixin";
import ActionButton from "@/components/Button/ActionButton.vue";
import EditButton from "@/components/Button/EditButton.vue";

const PriceListModule = namespace("price-list");
const PriceListFilterModule = namespace("price-list/localFilter");
const PriceModule = namespace("price");
const ProductModule = namespace("product");
const LicenseClassModule = namespace("license-class");
const VatModule = namespace("vat");
const GrantingTypeModule = namespace("granting-type");

const priceListResource = "price-lists";
const productResource = "products";
const licenseClassResource = "license-classes";
const vatResource = "vat-options";
const priceResource = "prices";
const grantingTypeResource = "granting-types";

@Component({
  components: {
    ActionButton,
    DeleteModal,
    FscModal,
    CopyButton,
    ArchiveModal,
    PrintButton,
    ArchiveButton,
    RemoveButton,
    PriceEditForm,
    PriceCombinationForm,
    Table,
    CreateButton,
    Actions,
    CreateCard,
    FscCard,
    FscCardTable,
    FscPageHeader,
    PriceListFilter,
    EditButton,
  },
})
export default class PriceLists extends mixins(PriceMixin, ModalMixin, CompanyMixin) {
  public name = "PriceLists";

  public visibleCreateCard = false;
  public visibleEditCard = false;

  @PriceListFilterModule.State("filter")
  public priceListLocalFilter: any;

  @PriceListFilterModule.Mutation("SET_LOCAL_FILTER")
  public setPriceListLocalFilter!: (payload: Record<any, any>) => void;

  // Price list
  @PriceListModule.Action("findAll")
  protected priceListFindAll: any;

  @PriceListModule.Action("create")
  protected priceListCreate: any;

  @PriceListModule.Action("priceListRename")
  protected priceListRenameAction: any;

  @PriceListModule.Getter("getDataList")
  protected priceLists!: Array<any>;

  @PriceListModule.Getter("getSuccess")
  protected priceListSuccess: any;

  @PriceListModule.Getter("getIsLoading")
  public priceListIsLoading: any;

  @PriceListModule.Getter("getError")
  public error: any;

  @PriceListModule.Action("copyPriceList")
  public copyPriceList: any;

  @PriceListModule.Getter("getPriceListCopy")
  public priceListCopy: any;

  @PriceListModule.Action("archive")
  private archiveAction: any;

  @PriceListModule.Getter("getArchiveSuccess")
  public archiveSuccess: any;

  // Price
  @PriceModule.Action("findAllByPriceList")
  protected priceFindAllByPriceList: any;

  @PriceModule.Action("combination")
  protected priceCombination: any;

  @PriceModule.Getter("getSuccess")
  protected priceCombinationSuccess: any;

  @PriceModule.Action("update")
  protected priceUpdate: any;

  @PriceModule.Getter("getDataList")
  protected prices: any;

  @PriceModule.Getter("getSuccess")
  protected priceSuccess: any;

  @PriceModule.Getter("getIsLoading")
  public priceIsLoading: any;

  @PriceModule.Action("del")
  public delete: any;

  @PriceModule.Action("copyToExistStudent")
  public copyToExistStudent!: (id: number) => Promise<void>;

  @PriceModule.Action("filter")
  public pricesFilter: any;

  // Product
  @ProductModule.Action("filter")
  public productFilter: any;

  @ProductModule.Getter("getDataList")
  public products: any;

  @ProductModule.Action("checkBookAtStudentRegistrationAllowed")
  public checkBookAtStudentRegistrationAllowed: any;

  @ProductModule.Getter("getIsBookAtStudentRegistrationAllowed")
  public isBookAtStudentRegistrationAllowed: any;

  // License Class
  @LicenseClassModule.Action("findAll")
  public findAllLicenseClasses: any;

  @LicenseClassModule.Getter("getDataList")
  public licenseClasses: any;

  // Vat
  @VatModule.Action("findAll")
  public findAllVat: any;

  @VatModule.Getter("getDataList")
  public vats: any;

  // Granting type
  @GrantingTypeModule.Action("findAll")
  public findAllGrantingType!: (options: ICrudOptions) => Promise<void>;

  @GrantingTypeModule.Getter("getDataList")
  public grantingTypes!: Array<IBasicNamedDTO>;

  protected deleteModalId = "delete-price-list-modal";
  protected priceListFilterModalId = "priceListFilterPopover";
  protected isDataLoading = false;

  public priceFields = [
    {
      key: "product.name",
      label: this.$tc("general.product", 1),
      sortable: false,
    },
    {
      key: "licenseClass",
      label: this.$tc("general.license_class"),
      sortable: false,
    },
    {
      key: "grantingType.name",
      label: this.$tc("general.granting_type"),
      sortable: false,
    },
    {
      key: "vat",
      label: this.$tc("price_list.vat"),
      sortable: false,
    },
    {
      key: "bookAtStudentRegistration",
      label: this.$tc("general.book_goods"),
      sortable: false,
    },
    {
      key: "priceInEur",
      label: this.$tc("students.price"),
      sortable: false,
    },
    {
      key: "priceInEurForAdditionalClass",
      label: this.$tc("students.second_price"),
      sortable: false,
    },
  ];

  protected selectedPriceList: { id: number } | null = null;

  protected visiblePriceTable = false;
  protected visibleCombinationForm = false;
  protected visiblePriceEditForm = false;
  protected priceSelectedRowItem: { id: number } | null = null;

  protected archived = false;

  protected modalId = "copy-to-exist-student-modal";

  public filterActiveIcon = false;

  protected mounted(): void {
    if (this.priceListLocalFilter instanceof Object) {
      this.archived = this.priceListLocalFilter.archived;
    }
    this.fetchPriceList();

    this.findAllVat({
      resource: vatResource,
    });

    this.findAllGrantingType({
      resource: grantingTypeResource,
      descriptionField: "",
    });
  }

  public fetchPrices(): void {
    if (!this.selectedPriceList) return;
    this.filterActiveIcon = false;
    this.pricesFilter({
      resource: "prices",
      filter: { priceListId: this.selectedPriceList.id },
    });
  }

  public fetchPriceList(): void {
    this.priceListFindAll({
      resource: priceListResource,
      params: { fetchNumberOfStudents: true, archived: this.archived },
    });
  }

  protected async onSubmitPriceList(item: any): Promise<void> {
    if (item.template.id === BLANK_ID) {
      await this.priceListCreate({
        resource: priceListResource,
        data: {
          name: item.priceListName,
        },
      });
    } else if (item.template.id === STANDARD_ID) {
      await this.priceListCreate({
        resource: `${priceListResource}/create-standard?name=${item.priceListName}`,
        data: null,
      });
    } else {
      await this.copyPriceList({
        id: item.template.id,
        name: item.priceListName,
      });
    }
    if (this.priceListSuccess) {
      this.visibleCreateCard = false;
      this.fetchPriceList();
    }
  }

  protected onAbortPriceList(): void {
    this.visibleCreateCard = false;
  }

  protected onAbortEditPriceList(): void {
    this.visibleEditCard = false;
  }

  public onCreate(): void {
    this.visibleCreateCard = true;
    this.visibleEditCard = false;
    this.closePriceTable();
    this.closePriceEditForm();
    this.closeCombinationForm();
  }

  public onEdit(): void {
    if (!this.selectedPriceList) return;
    this.visibleCreateCard = false;
    this.visibleEditCard = true;
    this.closePriceTable();
    this.closePriceEditForm();
    this.closeCombinationForm();
  }

  public async onSubmitEditPriceList(item: any) {
    const name = item.priceListName;
    const id = this.selectedPriceList?.id;
    await this.priceListRenameAction({ id, name });
    this.visibleEditCard = false;
    this.fetchPriceList();
  }

  public onClickRow(item: any): void {
    if (!item) return;
    this.selectedPriceList = item;
    this.fetchPrices();
    this.closePriceEditForm();
    this.closeCombinationForm();
    this.openPriceTable();
  }

  public get priceOption(): Array<any> {
    if (!this.prices) return [];
    return this.prices;
  }

  public onCreatePrice(): void {
    this.productFilter({
      resource: productResource,
      filter: {
        archived: false,
        searchTerm: "",
      },
    });
    this.findAllLicenseClasses({
      resource: licenseClassResource,
    });
    this.closePriceEditForm();
    this.openCombinationForm();
  }

  protected get productOption(): Array<any> {
    return this.products;
  }

  protected get grantingTypesOptions(): Array<IBasicNamedDTO> {
    return this.grantingTypes;
  }

  protected get licenseClassOption(): Array<any> {
    return this.licenseClasses;
  }

  protected get vatOptions(): Array<any> {
    return this.vats;
  }

  protected async onSubmitPriceCombination(data: any): Promise<void> {
    if (!this.selectedPriceList) return;
    await this.priceCombination({
      priceListId: this.selectedPriceList.id,
      productId: data.product.id,
      licenseClasses: data.licenseClass,
      grantingTypeIds: data.grantingType,
      vatId: data.vat.id,
      bookAtStudentRegistration: data.bookAtStudentRegistration,
      enablePriceListForDrivebuzz: true,
    });

    if (this.priceCombinationSuccess) {
      this.fetchPrices();
      this.closeCombinationForm();
    }
  }

  public get archivedOption(): Array<any> {
    return [
      {
        text: this.$tc("general.active", 2),
        value: false,
      },
      {
        text: this.$tc("general.archived", 2),
        value: true,
      },
    ];
  }

  public onChangeArchived(): void {
    this.setPriceListLocalFilter({ archived: this.archived });
    this.fetchPriceList();
  }

  protected reformatBookAtStudentRegistration(value: boolean): TranslateResult {
    if (value) {
      return this.$t("general.yes");
    }
    return this.$t("general.no");
  }

  protected openPriceTable(): void {
    this.visiblePriceTable = true;
  }

  protected closePriceTable(): void {
    this.visiblePriceTable = false;
  }

  protected openCombinationForm(): void {
    this.visibleCombinationForm = true;
  }

  protected closeCombinationForm(): void {
    this.visibleCombinationForm = false;
  }

  protected onAbortPriceCombination(): void {
    this.closeCombinationForm();
  }

  protected openPriceEditForm(): void {
    this.visiblePriceEditForm = true;
  }

  protected closePriceEditForm(): void {
    this.visiblePriceEditForm = false;
  }

  protected onPriceRowClicked(data: any): void {
    this.closeCombinationForm();
    this.openPriceEditForm();
    this.priceSelectedRowItem = data;
  }

  protected async onSubmitPriceEdit(data: any): Promise<void> {
    if (!this.priceSelectedRowItem) return;

    const { id } = this.priceSelectedRowItem;

    await this.priceUpdate({
      id: id,
      resource: priceResource,
      data: {
        ...this.priceSelectedRowItem,
        ...data,
      },
    });

    if (this.priceSuccess) {
      this.fetchPrices();
      this.closePriceEditForm();
    }
  }

  protected onAbortPriceEdit(): void {
    this.closePriceEditForm();
  }

  protected reformatVat(value: any): string {
    return `${value.vat.percentageAmount}%`;
  }

  protected onPrint(): void {
    if (!this.selectedPriceList) return;
    this.isDataLoading = true;

    getFile(
      {
        method: "GET",
        url: `/price-lists/pdf?priceListId=${this.selectedPriceList.id}`,
      },
      true,
      true
    )
      .then(() => {
        this.isDataLoading = false;
      })
      .catch(() => {
        this.isDataLoading = false;
      });
  }

  protected onDelete(): void {
    if (!this.priceSelectedRowItem) return;

    this.$bvModal.show(this.deleteModalId);
  }

  private showArchiveModal(): void {
    if (!this.selectedPriceList) {
      return;
    }
    this.showModal("price-list-archive-modal");
  }

  @Watch("archiveSuccess")
  private onArchiveSuccess(success: boolean): void {
    if (success) {
      this.fetchPriceList();
      this.visiblePriceTable = false;
      this.visiblePriceEditForm = false;
    }
  }

  @Watch("error")
  protected onError(error: AxiosError): void {
    if (error) {
      this.$toasted.error(error.message);
    }
  }

  protected onAssignNewPriceToStudents(): void {
    if (!this.priceSelectedRowItem) return;
    this.$bvModal.show(this.modalId);
  }

  protected copyToExistStudentConfirmed(): void {
    if (!this.priceSelectedRowItem) return;
    this.copyToExistStudent(this.priceSelectedRowItem.id);
  }

  protected async deleteModalConfirmed(): Promise<void> {
    if (!this.priceSelectedRowItem) return;

    await this.delete({
      resource: priceResource,
      id: this.priceSelectedRowItem.id,
    });

    this.fetchPrices();
  }

  public onFilterModalPopup(): any {
    this.findAllLicenseClasses({
      resource: licenseClassResource,
    });
    this.$bvModal.show("priceListFilterPopover");
  }

  public async onPriceListFilter(data: { filter: any }): Promise<void> {
    await this.pricesFilter({
      resource: "prices",
      filter: data.filter,
    });
    this.filterActiveIcon = true;
    this.$bvModal.hide("priceListFilterPopover");
  }

  public async onAbortPriceListFilter(data: { filter: any }): Promise<void> {
    await this.pricesFilter({
      resource: "prices",
      filter: data.filter,
    });
    this.filterActiveIcon = false;
  }

  public async localStorageGetPriceListFilter(filter: any): Promise<void> {
    const savedPriceListFilter = JSON.parse(filter);
    const temporary = {
      offset: 0,
      searchTerm: null,
      priceListId: this.selectedPriceList ? this.selectedPriceList.id : null,
      licenseClassNames: savedPriceListFilter.licenseClassNames,
      grantingTypeId: savedPriceListFilter.grantingTypeId,
      vatId: savedPriceListFilter.vatId,
      bookAtStudentRegistration: savedPriceListFilter.bookAtStudentRegistration,
    };
    await this.pricesFilter({
      resource: "prices",
      filter: temporary,
    });
    this.filterActiveIcon = true;
  }
}
