



























import { Component, Prop, Vue } from "vue-property-decorator";
import VerticalDivider from "@/components/VerticalDivider.vue";

@Component({
  components: { VerticalDivider },
})
export default class FscCardTable extends Vue {
  public name = "FscCardTable";

  @Prop({ type: Array, default: () => [] })
  public items!: any;

  public activeItem = null;

  protected onClickRow(item: any): void {
    this.activeItem = item;
    this.$emit("on-click-row", item);
  }

  protected isActive(item: any): boolean {
    return Object.is(item, this.activeItem);
  }
}
